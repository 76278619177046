d<template>
  <div class="card card-body shadow-sm notify-list">
    <app-modal-confirm
    ref="entry_modal_delete"
    :modal-header-title="$t('common.delete confirm title')"
    @confirmed="deleteEntry(entry.delete_id)"
    >
        <template v-slot:body>
            <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
        </template>
    </app-modal-confirm>
    <validation-observer
    ref="form_create_entry"
    v-slot="{ passes, valid, validated }"
    >
        <form
            class="from-create form-horizontal"
            v-on:submit.prevent="passes(handleCreateClick)"
        >
            <app-basic-table
            ref="basic_table"
            :table-name="$t('users.user list')"
            :filters.sync="filters"
            :setting-columns="columns"
            :endpoint-list="ENDPOINT.SETING_NOTIFICATION_MAIL_LIST"
            >
                <template v-slot:body-cell-apartment_id="props">
                    <td class="app-align-middle">
                        <p
                            :data-original-title="getApartmentName(props.row.apartment_id)"
                            style="color: #007bff"
                            @click="handlerApartmentNameClick(props.row)"
                            class="apartment_name app-table-p app-cell-tooltip mb-0"
                        >
                            {{ getApartmentName(props.row.apartment_id) }}
                        </p>
                    </td>
                </template>

                <template v-slot:body-cell-delete="props">
                    <td class="app-align-middle text-center app-p-none">
                        <a
                            @click="handleDeleteConfirm(props.row.id)"
                            class="btn btn-danger btn-linkflex"
                        >
                            {{ $t("common.delete") }}
                        </a>
                    </td>
                </template>

                <template v-slot:table-footer>
                    <tfoot>
                        <tr v-for="(item, index) in requests" :class="'tr_columns_' + index" v-if="item.available" :key="index">
                            <td></td>
                            <td class="app-align-middle">
                                <div class="app-table-p app-cell-tooltip mb-0">
                                    <div class="d-flex justify-content-between">
                                        <div class="col-left">
                                            <app-input
                                            :app-key="index"
                                            name="email"
                                            type="string128"
                                            rules="required|email"
                                            v-model.trim="item.email"
                                            />
                                        </div>
                                        <span class="app-require">＊</span>
                                    </div>
                                </div>
                            </td>
                            <td class="app-align-middle">
                                <div class="d-flex flex-row justify-content-between">
                                    <div class="col-left">
                                        <app-select
                                            :app-key="index"
                                            name="apartment_id"
                                            :options-data="meta.apartments"
                                            rules="required"
                                            v-model="item.apartment_id"
                                        />
                                        </div>
                                        <span class="app-require">＊</span>
                                </div>
                            </td>
                            <td class="app-align-middle text-center app-p-none">
                                <button
                                    type="button"
                                    @click="handleRemoveFieldInput(index)"
                                    class="btn btn-danger btn-linkflex"
                                    :id="'columns_' + index"
                                >
                                  {{ $t("common.delete") }}
                                </button>
                            </td>
                        </tr>
                    </tfoot>
                </template>
            </app-basic-table>

            <div class="card-footer row justify-content-end"
                 style="display: grid; grid-gap: 10px;">
              <button
                @click="handleBtnAddClick"
                type="button"
                class="btn btn-primary"

              >
                {{ $t("notifies.add_new_field") }}
              </button>
                <button
                    :disabled="(!valid && validated) || !checkInputData()"
                    type="submit"
                    class="btn btn-primary"
                    style="margin-top: 20px"
                >
                    {{ $t("common.save") }}
                </button>
            </div>
        </form>
    </validation-observer>
  </div>
</template>
<script>
import ja from "../../../validate/ja";
import { localize } from "vee-validate";
import { createHmac } from "crypto";
import { __noticeWarning } from "../../../utils";
import AppBasicTable from "@components/_common/list/AppBasicTable";
export default {
    name: "NotifiesList",

    components: {
        "app-basic-table": AppBasicTable,
    },

    data() {
        const id = this.$route.params.id;
        return {
            idCache: null,
            paramGetList: {},
            entry: {
                email: "",
                apartment_id: "",
            },
            filters: {},
            columns: [
                { name: "id", label: this.$t("common.id"), textAlign: "text-center" },
                { name: "email", label: this.$t("notifies.email") },
                { name: "apartment_id", label: this.$t("notifies.apartment") },
                { name: "delete", label: this.$t("common.delete") },
            ],
            meta: {
                apartments: [],
            },
            requests: [
            ],
        };
    },

    watch: {
        "entry.apartment_id": async function (value) {
            if (value) {
                await this.getMetaData({ apartment_id: value });
            } else {
                this.meta.apartments = [
                    {
                        id: "",
                        name: this.$t("common.choose"),
                    },
                ];
            }
        },
    },

    async mounted() {
        await this.getMetaData({});
    },

    methods: {
        async getMetaData(params) {
            this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER, params).then((res) => {
                this.meta = {
                    apartments: res.data.apartments,
                };
                this.meta.apartments.unshift({
                    id: "",
                    name: this.$t("common.choose"),
                });
            });
        },

        async handleCreateClick() {
            let res = null;
            let msg = "";
            let params = this.requests.filter(function (el) {
              return el.available === true
            });

            // Check duplicate email & apartment_id between input and exist data
            let checkDuplicate1 = false;
            await this.$request.get(this.ENDPOINT.SETING_NOTIFICATION_MAIL_LIST).then(res => {
                let notifies = res.data.data;
                if (notifies && params) {
                    $.each(notifies, function(index, notify) {
                        $.each(params, function(index, param) {
                            if ((notify.email == param.email) && (notify.apartment_id == param.apartment_id)) {
                                checkDuplicate1 = true;
                            }
                        });
                    });
                }
            });

            // Check duplicate email & apartment_id between inputs
            let checkDuplicate2 = false;
            let newParams = [];
            for (var i = 0; i < params.length; i++) {
                let checkStr = params[i].email + '@' + params[i].apartment_id;
                if (!newParams.includes(checkStr)) {
                    newParams.push(checkStr);
                }
            }
            if (params.length != newParams.length) {
                checkDuplicate2 = true;
            }

            // If duplicate data, display error only
            if (checkDuplicate1 || checkDuplicate2) {
                if (checkDuplicate1) {
                    this.__noticeError(this.$t("common.msg duplicate email between input and exist data"));
                } else {
                    this.__noticeError(this.$t("common.msg duplicate email between inputs"));
                }
            } else {
                res = await this.$request.post(this.ENDPOINT.SETING_NOTIFICATION_MAIL_CREATE, params);
                msg = this.$t("common.msg create ok");

                if (res.hasErrors()) {
                    this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t("common.error"));
                    if (res.status === 'error') {
                      this.logoutAction()
                    }
                } else {
                    this.__noticeSuccess(msg);

                    // Clear the input rows
                    this.requests = [];

                    // Refresh table data
                    this.$refs.basic_table.getEntries();

                    // Refresh form validate
                    this.$refs.form_create_entry.reset();
                }
            }
        },

        handleDeleteConfirm(id) {
            this.entry.delete_id = id;
            this.$refs.entry_modal_delete.show();
        },
        async deleteEntry(id) {
            const res = await this.$request.delete(
            this.ENDPOINT.SETING_NOTIFICATION_MAIL_DELETE(id)
        );
        if (!res.hasErrors()) {
            this.__noticeSuccess(this.$t("common.msg delete ok"));

            // Hide the confirm popup
            this.$refs.entry_modal_delete.hide();

            // Refresh table data
            this.$refs.basic_table.getEntries();
        } else {
                this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t("common.error"));
            }
        },

        handlerApartmentNameClick(entry) {
            let routeApartment = this.$router.resolve({
                name: this.ROUTES.ADMIN.APARTMENT_EDIT,
                params: { id: entry.apartment_id },
            });
            window.open(routeApartment.href, '_blank');
        },

        getApartmentName(apartment_id) {
            let apartment_name = this.meta.apartments.find(
                ({ id }) => id === apartment_id
            );
            if (apartment_name == undefined) {
                return "";
            }
            return apartment_name.name;
        },

        getApartmentEditUrl(apartment_id) {
            let props = this.$router.resolve({
                name: this.ROUTES.ADMIN.APARTMENT_EDIT,
                params: { id: apartment_id },
            });
            return props.href;
        },

        handleBtnAddClick() {
            this.requests.push({
                email: "",
                apartment_id: "",
                available : true
            });
            let sumRquest = this.requests.length;

            if (sumRquest > 1 && !ja.names[`email-${sumRquest - 1}`]) {
                ja.names[`email-${sumRquest - 1}`] = "Eメール";
                ja.names[`apartment_id-${sumRquest - 1}`] = "マンション";
                localize('ja', ja);
            }
        },
      handleRemoveFieldInput(key) {
        this.requests[key].available = false
        // this.requests = this.removeEmptyKeysFromObject(this.requests);
        // console.log(this.requests)
        // this.requests.splice(key, 1); // 2nd parameter means remove one item only
        console.log(this.requests)

      },
      removeEmptyKeysFromObject(obj) {
        Object.keys(obj).forEach(key => {
          console.log(obj[key]);
        });
        return obj;
      },
      checkInputData() {
       let data =  this.requests.filter(function (el) {
          return el.available === true
        })
        return data.length
      }
    },
};
</script>
